@import '../../styles/customMediaQueries.css';
@import '../../styles/marketplaceDefaults.css';

.landingRoot {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero {
  padding-top: 32px;

  width: 100%;
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    padding: 84px;

    width: 100%;
    background-color: var(--colorWhite);
  }
}

.heroContent {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 100%;

  @media (--viewportLarge) {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--colorWhite);
  }
}

.fade {
  opacity: 0;
}

.heroText {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: fit-content;

  padding: 20px;
  box-sizing: border-box;

  h1 {
    font-size: 32px;
    line-height: 1;
    color: var(--colorGrey900);

    @media (--viewportSmall) {
      font-size: 40px;
      line-height: 1;

      text-wrap: balance;
      color: var(--colorGrey900);
    }

    @media (--viewportLarge) {
      font-size: 48px;
      font-weight: 800;
      line-height: 1.2;
      text-wrap: balance;
      color: var(--colorGrey900);
    }
  }

  h2 {
    font-size: 28px;
    line-height: 0.8;
    color: var(--spxGreen);

    @media (--viewportSmall) {
      font-size: 40px;
      line-height: 0.8;

      text-wrap: balance;
      color: var(--colorGrey900);
    }

    @media (--viewportLarge) {
      font-size: 48px;
      font-weight: 800;
      line-height: 1;
      text-wrap: balance;
      color: var(--spxGreen);
    }
  }

  p {
    font-size: 14px;

    font-weight: bolder;

    @media (--viewportSmall) {
      font-size: 16px;
      font-weight: bolder;
    }
  }
}

.heroImage {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: fit-content;
}

.heroNamedLink {
  composes: buttonDefault from global;
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0 16px;
  min-height: 40px;

  &:hover {
    text-decoration: none;
  }
}

.rootForHeroImage {
  width: 100%;
}

.elections {
  padding: 24px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(255, 255, 255, 0.9)),
    url('../../assets/promo-bg-cloud.png');
  background-size: cover;
  background-position: 100% 50%;
  width: 100%;

  @media (--viewportMedium) {
    padding: 24px;
    width: 80%;

    border-radius: 8px;
    border: 1px solid var(--colorGrey100);
    background-image: url('../../assets/promo-bg-cloud.png');
    background-size: cover;
    background-position: 100% 50%;
  }
}

.electionsText {
  padding: 16px;

  h3 {
    font-size: 20px;
    font-weight: 500;
    color: var(--colorWhite);
  }

  p {
    font-size: 20px;
    color: var(--spxGreen);
    font-weight: 600;
  }

  @media (--viewportMedium) {
    h3 {
      font-size: 24px;
      padding: 0;
      margin: 0;
      color: var(--colorBlack);
    }

    p {
      font-size: 24px;
      color: var(--spxGreen);
      padding: 0;
      font-weight: 600;
    }
  }
}

.specific {
  padding: 24px;
  background-image: url('../../assets/promo-bg-hand-with-bulb.png');
  background-size: cover;
  background-position: 80% 50%;
  width: 100%;

  @media (--viewportMedium) {
    padding: 24px;
    width: 80%;

    border-radius: 8px;
    border: 1px solid var(--colorGrey100);
    background-image: url('../../assets/promo-bg-hand-with-bulb.png');
    background-size: cover;
  }
}

.specificText {
  text-align: center;

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 12px;
    margin: 8px 0;
  }

  @media (--viewportMedium) {
    text-align: center;

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 20px;
    }
  }
}

.electionsNamedLink {
  composes: buttonPrimary from global;
  width: fit-content;
  padding: 0px 16px;
  min-height: 28px;
  display: flex;
  align-items: center;

  @media (--viewportLarge) {
    width: fit-content;
    padding: 0px 16px;
    min-height: 40px;
  }

  &:hover {
    text-decoration: none;
  }
}

.specificNamedLink {
  composes: buttonPrimary from global;
  width: fit-content;
  padding: 0px 16px;
  min-height: 28px;
  display: flex;
  align-items: center;
  margin: auto;

  @media (--viewportLarge) {
    width: fit-content;
    padding: 0px 16px;
    min-height: 40px;
  }

  &:hover {
    text-decoration: none;
  }
}

.listings {
  max-width: 80%;
  margin: auto;
  padding-bottom: 48px;
  padding-top: 48px;
}

.showMoreLink {
  composes: buttonPrimary from global;
  padding: 0;
  min-height: 38px;

  color: white;
  min-width: 120px;
  text-wrap: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
  }
  &:hover {
    text-decoration: none;
  }
}

.dotNavigation {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  background-color: var(--colorWhite);
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 4px;
  border: 2px solid var(--spxGreyLightest);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  padding-inline: 0;
}

.activeDot {
  height: 12px;
  width: 12px;
  margin: 0 4px;
  border: none;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  background-color: var(--spxGreyLightest);
}
